<!--
 * @Descripttion: 推广人品牌分账金额列表
 * @version: 
 * @Author: dxl
 * @Date: 2023-08-20 14:26:00
 * @LastEditors: dxl
 * @LastEditTime: 2023-08-24 14:07:29
-->
<template>
    <div class="LedgerUserList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">推广人品牌分账金额列表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <div class="sw-left">
                    <div class="search-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="sw-right">
                    <el-button class="btns" @click="clickAdd()" type="primary">添加</el-button>
                </div>
            </div>

            <!-- 表格 -->
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border stripe style="width: 100%">
                    <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>
                    <el-table-column align="center" prop="enterpriseBrand.name" label="品牌名称"  :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="enterpriseBrand.EpPrice" label="品牌经销商销售单价(元)"  width="200" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="commission" label="分账金额(元)"  width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <el-tag size="medium" type="danger">{{ scope.row.commission }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="create_timex" label="创建时间" width="170" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" label="操作" width="200"  fixed="right">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item pointer" effect="dark" content="编辑" placement="top">
                                    <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit-outline"></i>
                                </el-tooltip>
                                <el-tooltip class="item pointer" effect="dark" content="删除" placement="top">
                                    <i @click="clickDel(scope.row)" class="iconfontx el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- 编辑弹窗 -->
            <el-dialog
            :visible.sync="isShowEditInfo" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title">设置分账金额</div>
            <div class="content">
                <div class="item" >
                    <div class="tit">选择品牌</div>
                    <el-select 
                    @change="changeEP"
                    v-model="edit_info.ep_name" 
                    :disabled="edit_id_bl"
                    filterable 
                    placeholder="请选择品牌">
                        <el-option v-for="item in epOptions" :key="item.id" :label="item.name" :value="item.id" >
                        </el-option>
                    </el-select>
                </div>
                <div class="item" >
                    <div class="tit-red">设置分账金额（元）</div>
                    <div >
                        <el-input-number v-model="edit_info.price" controls-position="right" :precision="2" :step="1" :max="500" :min="0.01"></el-input-number>
                    </div>
                </div>
                <div class="btn item">
                    <el-button v-if="edit_id" @click="submitEdit()" type="primary">保存</el-button>
                    <el-button v-else @click="submitAdd()" type="primary">添加</el-button>
                </div>
            </div>
            </el-dialog>


        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    mixins:[pageMixin],
    data () {
        return {
            loading: false,
            tableData: [],
            keyword: '',

            commissionerParam:{},

            isShowEditInfo:false,
            edit_id:'',
            edit_id_bl:false,
            edit_info: {
                ep_id: '',
                ep_name: '',
                price: ''
            },
            epOptions:[]

        }
    },
    mounted () {
        this.commissionerParam = this.$route.query
        if(!this.commissionerParam.id){
            this.$message.error("页面参数异常！");
        }else{
            this.LedgerEpList();
            this.initEpList();
        }
    },
    methods: {
        // 添加
        clickAdd(){
            this.edit_id = '';
            this.edit_id_bl = false;
            this.edit_info.ep_id = '';
            this.edit_info.ep_name = '';
            this.edit_info.price = '';
            this.isShowEditInfo = true;
        },
        // 添加，提交
        submitAdd(){
            let params = {
                promoter_id: this.commissionerParam.id,
                ep_id: this.edit_info.ep_id,
                price: this.edit_info.price,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/setPromoterEPprice', params, (res) => {
                this.loading = false;
                this.isShowEditInfo = false;
                this.LedgerEpList();
                this.initEpList();
            })
        },
        // 编辑
        clickEdit(row){
            this.edit_id = row.id;
            this.edit_id_bl = true;
            this.edit_info.ep_id = row.ep_id;
            this.edit_info.ep_name = row.enterpriseBrand.name;
            this.edit_info.price = row.commission;
            this.isShowEditInfo = true;
        },
        // 编辑，提交
        submitEdit(){
            let params = {
                id: this.edit_id,
                price: this.edit_info.price,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/setPromoterEPpriceEdit', params, (res) => {
                this.loading = false;
                this.isShowEditInfo = false;
                this.LedgerEpList();
            })
        },
        // 删除
        clickDel(row){
            this.$confirm('此操作将删除该数据，此推广员的推广码之后将无法在该品牌使用, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    id: row.id,
                }
                this.loading = true;
                common.connect('/customerservicev1/Promoter/setPromoterEPpriceDel', params, (res)=>{
                    this.loading = false;
                    if(res.isSuccess == 1){
                        this.LedgerEpList();
                        this.initEpList();
                        this.$message.success(res.msg)
                    }
                })
            }).catch(() => {
                this.loading = false;   
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                }); 
                     
            });
        },
        changeEP(e){ 
            // console.log("changeEP");
            this.edit_info.ep_id = e;
        },
        // 获取数据
        LedgerEpList(){
            let params = {
                promoter_id: this.commissionerParam.id,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/getPromoterEPpriceList',params,(res)=>{
                this.tableData = res.data.list;
                this.loading = false;
            })
            
        },
        // 初始化
        initEpList(){
            let params = {
                promoter_id: this.commissionerParam.id,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/initPromoterEPprice',params,(res)=>{
                this.epOptions = res.data.eplist;
                this.loading = false;
            })
            
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.LedgerEpList();
        },

        handleClose(){
            this.isShowEditInfo = false;
        },
    },
}
</script>

<style lang='scss'>
.pointer{
  cursor: pointer;
}
.LedgerUserList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 20px;
            .sw-left{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .search-item{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-left: 20px;
                }
                .el-input{
                    width: 220px;
                    margin-right: 10px;
                }
            }
        }
        .table-wrap{
            margin-top: 20px;
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                .iconfont{
                    font-size: 17px;
                    padding: 0 10px;
                    cursor: pointer;
                }
                .iconfontx{
                    font-size: 17px;
                    color: red;
                    padding: 0 10px;
                    cursor: pointer;
                }
                .link{
                    cursor: pointer;
                    color: #409EFF;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .dialog-wrap{
        width: 340px;
        .title{
            text-align: center;
            font-size: 18px;
            color: #000;
        }
        .content{
            margin-left: 20px;
            .el-textarea{
                width: 500px;
            }
            .item{
                padding-bottom: 20px;
                .tit{
                    padding-bottom: 20px;
                    color: #000;
                }
                .tit-red{
                    padding-bottom: 20px;
                    color: red;
                }
                .el-button{
                    width: 300px;
                }
                .el-input{
                    width: 300px;
                }
                .el-input-number{
                    width: 300px;
                }
                .upload-wrap{
                    .fileList-box{
                        position: relative;
                        width: 250px;
                        border: 1px solid #eee;
                        border-radius: 6px;
                        padding: 20px 20px;
                        .delFile{
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            background: rgba(0, 0, 0, 0.2);
                            padding: 0 5px;
                            border-radius: 10px;
                            color: #fff;
                            cursor: pointer;
                            
                        }
                        .link{
                            width: 230px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .icon{
                                display: flex;
                                flex-wrap: wrap;
                                align-items: baseline;
                                .iconfont{
                                    font-size: 14px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
